import { Injectable } from "@angular/core";
import { throwError as observableThrowError, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  public url: string;
  public dato: any;
  public name: any = "sin nombre";

  URL_BACKEND = environment.BACKEND_URL;

  constructor(public _http: HttpClient) {
    this.name = this.name;
  }
  setName(nombre: string) {
    this.name = nombre;
  }
  getPDato() {
    return this.name;
  }
  /*   getProducto(id) {
    return this._http.get(this.URL_BACKEND + "producto/" + id);
  }
 */
  normalLogin(email, password): Observable<any> {
    // console.log(token)

    return this._http.post(this.URL_BACKEND + "/api/signin", {
      email,
      password,
    });
  }

  normalRegister(formulario) {
    // console.log(token)

    return this._http.post(this.URL_BACKEND + "/api/signin", formulario);
  }

  obtenerDatosAdmin(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this._http.get<any>(this.URL_BACKEND + "/api/user-account", {
      headers: headers,
    });
  }

  forgotPassword(email): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Basic " + localStorage.getItem("jwt")
    );

    return this._http.post(this.URL_BACKEND + "/api/sendcodeverification", {
      email,
    });
  }

  updatePass(email, code_verification, new_password): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Basic " + localStorage.getItem("jwt")
    );

    return this._http.post(this.URL_BACKEND + "/api/restore", {
      email,
      code_verification,
      new_password,
    });
  }

  verifyCode(email, code_verification): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Basic " + localStorage.getItem("jwt")
    );

    return this._http.post(this.URL_BACKEND + "/api/active/verifycode", {
      email,
      code_verification,
    });
  }
  actualizarPerfil(idTips, datosTips) {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );
    return this._http.put(this.URL_BACKEND + "/api/tips/" + idTips, datosTips, {
      headers: headers,
    });
  }
}
