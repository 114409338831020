import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export interface IMenuItem {
  id?: string;
  title?: string;
  description?: string;
  type: string; // Possible values: link/dropDown/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  sub2?: IChildItem2[]; // Dropdown items
  sub3?: IChildItem3[];
  badges?: IBadge[];
  active?: boolean;
}
export interface IChildItem {
  id?: string;
  parentId?: string;
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
  active?: boolean;
}
export interface IChildItem2 {
  id?: string;
  parentId?: string;
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem2[];
  active?: boolean;
}

export interface IChildItem3 {
  id?: string;
  parentId?: string;
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
  active?: boolean;
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

interface ISidebarState {
  sidenavOpen?: boolean;
  childnavOpen?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  public sidebarState: ISidebarState = {
    sidenavOpen: true,
    childnavOpen: false,
  };
  selectedItem: IMenuItem;
  items: IMenuItem[] = [];

  constructor() {}

  defaultMenu: IMenuItem[] = [];


  menuAdmin: IMenuItem[] = [
    {
      name: "Usuarios",
      state: "/pages/usuarios",
      type: "link",
      icon: "i-Administrator",
    },
    {
      name: "Empresas",
      state: "/pages/empresa",
      type: "link",
      icon: "i-Building",
    },
    {
      name: "Participantes",
      state: "/pages/participantes",
      type: "link",
      icon: "i-Ticket",
    },

    {
      name: "Configuración",
      state: "/pages/config",
      type: "link",
      icon: "i-Gear",
    },
  ];

  menuSecundario: IMenuItem[] = [
    {
      name: "Usuarios",
      state: "/pages/usuarios-cliente",
      type: "link",
      icon: "i-Administrator",
    },
    {
      name: "Participantes",
      state: "/pages/participantes",
      type: "link",
      icon: "i-Ticket",
    },
  ];

  chooseMenuItems(rol) {
    if (rol == "admin") {
      this.menuItems.next(this.menuAdmin);
    } else {
      if (rol == "super_admin") {
        this.menuItems.next(this.menuAdmin);
      }

      if (rol == "client") {
        this.menuItems.next(this.menuSecundario);
      }
    }
  }

  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  menuItems$ = this.menuItems.asObservable();
}
