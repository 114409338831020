import { EventEmitter, Injectable, Output } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ServiceChat {
  URL_BACKEND = environment.BACKEND_URL;

  constructor(private http: HttpClient) {}

  getChat(rol, id): Observable<any> {
    console.log(id);
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this.http.get<any>(
      this.URL_BACKEND + `/api/${rol}/list/winners-by-campaign/${id}`,
      {
        headers: headers,
      }
    );
  }
  @Output() ActualizarListaPersonas = new EventEmitter<any>();
  @Output() ActualizarChatPersona = new EventEmitter<any>();
  @Output() ActualizarChatPersonaConsulta = new EventEmitter<any>();

  @Output() VerificaEvento = new EventEmitter<any>();
  @Output() VerificaEventoMetodos = new EventEmitter<any>();

  @Output() TraerTodoPersonas = new EventEmitter<any>();
  @Output() TraerTodoConsulta = new EventEmitter<any>();
  @Output() ActualizarListaPersonasConsulta = new EventEmitter<any>();
  @Output() ActualizarChatRecibido = new EventEmitter<any>();

  @Output() EliminaTodoLosToast = new EventEmitter<any>();

  getClientCampañas(rol, id): Observable<any> {
    console.log(id);
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this.http.get<any>(
      this.URL_BACKEND + `/api/${rol}/list/campaigns-by-company/${id}`,
      {
        headers: headers,
      }
    );
  }

  getcompanies(rol): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this.http.get<any>(this.URL_BACKEND + `/api/${rol}/list/companies`, {
      headers: headers,
    });
  }

  getConversacion(rol, campana, competidor): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this.http.get<any>(
      this.URL_BACKEND +
        `/api/${rol}/list/conversation/${campana}/${competidor}`,
      {
        headers: headers,
      }
    );
  }

  getConversacionParticipante(rol, campana, competidor): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this.http.get<any>(
      this.URL_BACKEND +
        `/api/${rol}/list/competitor-conversation/${campana}/${competidor}`,
      {
        headers: headers,
      }
    );
  }
  updateEstado(rol, idwinner, idcampaing, estado, reason): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );
    return this.http.put<any>(
      this.URL_BACKEND +
        `/api/${rol}/update/winner/status/${idwinner}/${idcampaing}/${estado}`,
      { reason: reason },

      {
        headers: headers,
      }
    );
  }

  searchParticipantXCampaing(rol, id_campaing, query): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this.http.get<any>(
      this.URL_BACKEND + `/api/${rol}/search/winners/${id_campaing}/${query}`,
      {
        headers: headers,
      }
    );
  }

  searchParticipantAll(rol, query): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this.http.get<any>(
      this.URL_BACKEND + `/api/${rol}/search/winners/${query}`,
      {
        headers: headers,
      }
    );
  }

  getChatAll(rol): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this.http.get<any>(this.URL_BACKEND + `/api/${rol}/list/winners/`, {
      headers: headers,
    });
  }

  getChatAllConsulta(rol): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this.http.get(
      this.URL_BACKEND + `/api/${rol}/list/competitor-modo-consulta`,
      {
        headers: headers,
      }
    );
  }

  getChatConsultaXcampaing(rol, id): Observable<any> {
    console.log(id);
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this.http.get<any>(
      this.URL_BACKEND +
        `/api/${rol}/list/competitor-modo-consulta-by-campaign/${id}`,
      {
        headers: headers,
      }
    );
  }

  getConversacionConsulta(rol, campana, competidor): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this.http.get<any>(
      this.URL_BACKEND +
        `/api/${rol}/list/competitor-conversation/${campana}/${competidor}`,
      {
        headers: headers,
      }
    );
  }

  sendMessageConsulta(rol, competitor, data): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );
    return this.http.post<any>(
      this.URL_BACKEND + `/api/${rol}/chat/competidor/${competitor}`,
      { message: data },
      {
        headers: headers,
      }
    );
  }

  updateEstadoTerminarsesion(rol, id): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );
    return this.http.put<any>(
      this.URL_BACKEND +
        `/api/${rol}/update/competitor/finish-modo-consulta/${id}`,
      { modo_chat: false },

      {
        headers: headers,
      }
    );
  }

  getParticipantOther(rol, idCampaing, page): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this.http.get<any>(
      this.URL_BACKEND +
        `/api/${rol}/list/competitor/${idCampaing}?page=${page}`,
      {
        headers: headers,
      }
    );
  }

  /*http://localhost:4001/api/admin/list/competitor-modo-consulta-by-campaign/:id_campaign -
  /* getChatAllConsulta(rol): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-type", "application/json");
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("purple_token")
    );

    return this.http.get<any>(
      this.URL_BACKEND + `/api/${rol}/list/competitor-modo-consulta`,
      {
        headers: headers,
      }
    );
  }  */

  /*   http://localhost:4001/api/admin/list/competitor-modo-consulta-by-campaign/:id_campaign
   */
  /*
  router.get('/search/competitors/:search', searchAllCompetitor)
router.get('/search/competitors/:id_company/:search', searchByCompany)
  http://localhost:4001/api/admin//update/winner/status/3/104/rechazado */
}
