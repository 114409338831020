import { Component } from "@angular/core";
import { NavigationService } from "./shared/services/navigation.service";
import { RolService } from "./shared/services/rol.service";
import { LoginService } from "./views/sessions/service/login.service";
// import io from "socket.io-client/dist/socket.io";
import ClientIO from "socket.io-client";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  // socket: io.Socket;
  token =
    "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjcyIiwiZGlzcGxheW5hbWUiOiJ0ZXN0aW5nIDQ1MTIiLCJlbWFpbCI6ImhhaWxyaWRlcmR1ZHdhc2RmbW15QGRlbW8uY29tIiwidHlwZSI6InJpZGVyIiwiaWF0IjoxNTYzNDQxMDIyLCJleHAiOjE1NjYwMzMwMjJ9.ugvVG2jAF9bm4vg53pb9_3ZYM-taWMaaHjGTesHCVnE";
  SOC_URL = "http://localhost:4001";

  title = "bootDash";
  constructor(
    private _loginService: LoginService,
    private _sRol: RolService,     private toastr: ToastrService,
    private navigationService: NavigationService
  ) {
    if (localStorage.getItem("purple_token")) {
      this._loginService.obtenerDatosAdmin().subscribe((userResponse: any) => {
        this._sRol.setR(userResponse.type_user);
        this.navigationService.chooseMenuItems(userResponse.type_user);
      });
    } else {
      /*        this._sRol.cerrarSesion();
       */
    }
  }
  clientIO: any;
  ngOnInit() {
   /*  console.log("data");
    this.clientIO = ClientIO("http://localhost:4001");
    this.clientIO.on("notification", async (data) => {
      console.log("data", data);
      this.toastr.info(
        "Nuevo ganador.",
        `Revisa las validaciones para verificar que todo este en orden`,
        {
          timeOut: environment.timeOutmessage,
          closeButton: true,
          progressBar: true,
        }
      );
    }); */

  }
}
