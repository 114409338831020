import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { NavigationService } from "../../../../services/navigation.service";
import { SearchService } from "../../../../services/search.service";
import { AuthService } from "../../../../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import { RolService } from "src/app/shared/services/rol.service";
@Component({
  selector: "app-header-sidebar-large",
  templateUrl: "./header-sidebar-large.component.html",
  styleUrls: ["./header-sidebar-large.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSidebarLargeComponent implements OnInit {
  notifications: any[];

  nombreUsuario = "";
  userMgmt: any = {};
  userAvatar: any = {};
  fotoUsuario = "";

  //cambio de contraseña works
  workback: Boolean = false;
  newPassForm: FormGroup;

  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private auth: AuthService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _sRol: RolService,

    private toastr: ToastrService
  ) {
    this.obtenerNombreUsuario();
/*     this.obtenerFotoUsuario(); */

    this.notifications = [
      {
        icon: "i-Speach-Bubble-6",
        title: "New message",
        badge: "3",
        text: "James: Hey! are you busy?",
        time: new Date(),
        status: "primary",
        link: "/chat",
      },
      {
        icon: "i-Receipt-3",
        title: "New order received",
        badge: "$4036",
        text: "1 Headphone, 3 iPhone x",
        time: new Date("11/11/2018"),
        status: "success",
        link: "/tables/full",
      },
      {
        icon: "i-Empty-Box",
        title: "Product out of stock",
        text: "Headphone E67, R98, XL90, Q77",
        time: new Date("11/10/2018"),
        status: "danger",
        link: "/tables/list",
      },
      {
        icon: "i-Data-Power",
        title: "Server up!",
        text: "Server rebooted successfully",
        time: new Date("11/08/2018"),
        status: "success",
        link: "/dashboard/v2",
      },
      {
        icon: "i-Data-Block",
        title: "Server down!",
        badge: "Resolved",
        text: "Region 1: Server crashed!",
        time: new Date("11/06/2018"),
        status: "danger",
        link: "/dashboard/v3",
      },
    ];
  }

  ngOnInit() {
    this.newPassForm = this.fb.group({
      actualPassword: ["", Validators.required],
      newPassword: ["", Validators.required],
      repeatNewPassword: ["", Validators.required],
    });
  }
  architects;
  obtenerNombreUsuario() {
    if (localStorage.getItem("nombreCompleto")) {
      this.nombreUsuario = localStorage.getItem("nombreCompleto");
      this.architects = [{ title: this.nombreUsuario }];
    } else {
      this.nombreUsuario = this.userMgmt.name + " " + this.userMgmt.lastname;
      localStorage.setItem("nombreCompleto", this.nombreUsuario);
    }
  }
  currentAlphabet;
  checkIfNew(title: string) {
    if (this.currentAlphabet === title.charAt(0).toLowerCase()) {
      return true;
    } else {
      this.currentAlphabet = title.charAt(0).toLowerCase();
      return true;
    }
  }

  obtenerFotoUsuario() {
    if (localStorage.getItem("fotoUsuario")) {
      this.fotoUsuario = localStorage.getItem("fotoUsuario");
    } else {
      this.fotoUsuario = this.userAvatar.path;
      localStorage.setItem("fotoUsuario", this.fotoUsuario);
    }
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    if (state.childnavOpen && state.sidenavOpen) {
      return (state.childnavOpen = false);
    }
    if (!state.childnavOpen && state.sidenavOpen) {
      return (state.sidenavOpen = false);
    }
    // item has child items
    if (
      !state.sidenavOpen &&
      !state.childnavOpen &&
      this.navService.selectedItem.type === "dropDown"
    ) {
      state.sidenavOpen = true;
      setTimeout(() => {
        state.childnavOpen = true;
      }, 50);
    }
    // item has no child items
    if (!state.sidenavOpen && !state.childnavOpen) {
      state.sidenavOpen = true;
    }
  }

  signout() {
    this.auth.signout();
  }

  modalCambiarPassword(modal) {
    this.newPassForm.reset();
    this.modalService
      .open(modal, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          console.log(`Closed with: ${result}`);
          this.newPassForm.reset();
        },
        (reason) => {
          console.log(`Dismissed with: ${reason}`);
          this.newPassForm.reset();
        }
      );
  }
  cambiarPassword() {
    const decodedData = atob(localStorage.getItem("rol"));
    console.log(decodedData);
    //validar
    let { actualPassword, newPassword, repeatNewPassword } =
      this.newPassForm.value;
    if (newPassword !== repeatNewPassword) {
      this.toastr.info(
        "Debe repetir la nueva contraseña correctamente.",
        `Las contraseñas no son iguales.`,
        {
          timeOut: environment.timeOutmessage,
          closeButton: true,
          progressBar: true,
        }
      );
      return;
    }
    let obj = {
      current_password: actualPassword,
      new_password: newPassword,
    };
    // console.log(this.newPassForm.value);
    this.workback = true;
    this.auth.changePassword(obj, decodedData).subscribe(
      (resp) => {
        this.toastr.success(
          "La contraseña se cambio correctamente.",
          `La próxima vez que inicie sesión deberá usarla.`,
          {
            timeOut: environment.timeOutmessage,
            closeButton: true,
            progressBar: true,
          }
        );
        this.workback = false;
        this.modalService.dismissAll();
      },
      (error) => {
        if (error.status === 401) {
          this.auth.signout();
        }
        this.workback = false;
        this.toastr.error(
          "Error al cambiar la contraseña",
          `${error.error.errors[0].msg}`,
          {
            timeOut: environment.timeOutmessage,
            closeButton: true,
            progressBar: true,
          }
        );
      }
    );
  }
}
