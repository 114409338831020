import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../utils";
// import { Init } from "../../views/apps-vista/simulador-view/simulador-date";

@Injectable({
  providedIn: "root",
})
export class DataLayerService {
  constructor(private http: HttpClient) {
    // console.log("TodoService Works");
    // this.load();
  }

  getInvoices() {
    let todos = JSON.parse(localStorage.getItem("todos"));
    return this.http.get<any[]>("/api/invoices");
  }
  getInvoice(id) {
    return this.http.get<any[]>("/api/invoices/" + id);
  }
  saveInvoice(invoice) {
    if (invoice.id) {
      return this.http.put<any[]>("/api/invoices/" + invoice.id, invoice);
    } else {
      invoice.id = Utils.genId();
      return this.http.post<any[]>("/api/invoices/", invoice);
    }
  }
  deleteInvoice(id) {
    return this.http.delete<any[]>("/api/invoices/" + id);
  }
  getMails() {
    return this.http.get<any[]>("/api/mails");
  }
  getCountries() {
    return this.http.get<any[]>("/api/countries");
  }
  getProducts() {
    return this.http.get<any[]>("api/products");
  }

  getSimulador() {
    let todos = JSON.parse(localStorage.getItem("todos"));
    return todos;
  }

  addSimulador(newTodo) {
    let todos = JSON.parse(localStorage.getItem("todos"));
    todos.push(newTodo);
    localStorage.setItem("todos", JSON.stringify(todos));
  }

  updateTodo(oldText, newText) {
    let todos = JSON.parse(localStorage.getItem("todos"));

    for (let i = 0; i < todos.length; i++) {
      if (i == oldText) {
        todos[i] = newText;
      }
    }
    localStorage.setItem("todos", JSON.stringify(todos));
  }
}
