import { Component, OnInit, ViewChild } from "@angular/core";
import { NavigationService } from "../../../services/navigation.service";
import { SearchService } from "src/app/shared/services/search.service";
import { SharedAnimations } from "src/app/shared/animations/shared-animations";
import {
  Router,
  RouteConfigLoadStart,
  ResolveStart,
  RouteConfigLoadEnd,
  ResolveEnd,
} from "@angular/router";
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
//import ClientIO from "socket.io-client";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { Howl, Howler } from "howler";
import { ServiceChat } from "src/app/shared/services/service_chat.service";

@Component({
  selector: "app-admin-layout-sidebar-large",
  templateUrl: "./admin-layout-sidebar-large.component.html",
  styleUrls: ["./admin-layout-sidebar-large.component.scss"],
})
export class AdminLayoutSidebarLargeComponent implements OnInit {
  faBoxOpen = faBoxOpen;
  moduleLoading: boolean;
  @ViewChild(PerfectScrollbarDirective, { static: true })
  perfectScrollbar: PerfectScrollbarDirective;
  toastRef;

  constructor(
    public navService: NavigationService,
    public searchService: SearchService,
    private _chat: ServiceChat,
    private toastr: ToastrService,
    private router: Router
  ) {}
  clientIO: any;


  ngOnInit() {
    Howler.volume(0.5);
    let campaña = localStorage.getItem("empresa");
    console.log(campaña);
    this.router.events.subscribe((event) => {
      if (
        event instanceof RouteConfigLoadStart ||
        event instanceof ResolveStart
      ) {
        this.moduleLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.moduleLoading = false;
      }
    });
    const decodedData = atob(localStorage.getItem("rol"));
    const decodedDataS = atob(localStorage.getItem("rol"));

    console.log("data");
   // this.clientIO = ClientIO("https://promobotbe.misolucioneswow.com");
 

   
  }
}
